import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2'
import VueDatePicker from '@vuepic/vue-datepicker'
import Vue3EasyDataTable from 'vue3-easy-data-table'

import App from './App.vue'
import router from './router'
import store from './store'

axios.defaults.baseURL = 'https://app.passiongroup.pt/api/'

createApp(App)
	.use(store)
	.use(router)
	.use(VueAxios, axios)
	.use(VueSweetalert2)
	.component('EasyDataTable', Vue3EasyDataTable)
	.component('DatePicker', VueDatePicker)
	.mount('#app')
