import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/agenda',
    name: 'agenda',
    component: () => import('../views/Agenda.vue'),
  },
  {
    path: '/juridico',
    name: 'juridico',
    component: () => import('../views/Juridico.vue'),
  },
  {
    path: '/marketing',
    name: 'marketing',
    component: () => import('../views/Marketing.vue'),
  },
  {
    path: '/marketing-categorias',
    name: 'marketingCategorias',
    component: () => import('../views/MarketingCategorias.vue'),
  },
  {
    path: '/marketing-produtos',
    name: 'marketingProdutos',
    component: () => import('../views/MarketingProdutos.vue'),
  },
  {
    path: '/marketing-templates',
    name: 'marketingTemplates',
    component: () => import('../views/MarketingTemplates.vue'),
  },
  {
    path: '/fornecedores',
    name: 'fornecedores',
    component: () => import('../views/Fornecedores.vue'),
  },
  {
    path: '/fornecedores-categorias',
    name: 'fornecedoresCategorias',
    component: () => import('../views/FornecedoresCategorias.vue'),
  },
  {
    path: '/formacao',
    name: 'formacao',
    component: () => import('../views/Formacao.vue'),
  },
  {
    path: '/formacao/:id/:id1',
    name: 'licao',
    component: () => import('../views/Licao.vue'),
  },
  {
    path: '/formacao/:id',
    name: 'licoes',
    component: () => import('../views/Licoes.vue'),
  },
  {
    path: '/recrutamento',
    name: 'recrutamento',
    component: () => import('../views/Recrutamento.vue'),
  },
  {
    path: '/repositorio',
    name: 'repositorio',
    component: () => import('../views/Repositorio.vue'),
  },
  {
    path: '/utilizadores',
    name: 'utilizadores',
    component: () => import('../views/Utilizadores.vue'),
  },
  {
    path: '/lojas',
    name: 'lojas',
    component: () => import('../views/Lojas.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: 'about' */ '../views/Login.vue'),
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: () => import('../views/NotFound.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/notfound'
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
});


export default router
