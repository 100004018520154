<template>
  <div>
    <!-- Botão para abrir o menu -->
    <!--  <button class="side-menu-toggle" @click="toggleSideMenu" v-if="!isOpen">
      ☰
    </button> -->

    <div class="side-sidemenu" :class="{ 'side-sidemenu--open': isOpen }">
        <ul class="nav">
          <li v-for="item in menuItems" :key="item.name" class="newli">
            <router-link :to="item.path" class="popup" @click="closeSideMenu">
              <div
                :class="{ 'linkcontainer-active': $route.name === item.name }"
                class="linkcontainer"
              >
                <!-- <span v-if="!isSideMenuOpen" class="popuptext popuptextSidebar show">
                  {{ item.title }}
                </span> --> 
                <span v-html="item.icon"></span>
                <span class="linktitle">{{ item.title }}</span>
              </div>
            </router-link>
          </li>
        </ul>
    </div>

    <!-- Overlay se quiseres sombra-->
    <!-- <div class="side-overlay" v-if="isOpen" @click="toggleSideMenu"></div> -->
  </div>
</template>

<script>
import { menuItems } from '@/utils/menu';

export default {
  name: "SideMenu",
  data() {
    return {
      isOpen: false,
      menuItems
    };
  },
  methods: {
    hasPermission(item) {
      const userPermissions = this.$store.state.user;
      return item.permissions.some(permission => userPermissions[permission]);
    },
    toggleSideMenu() {
      this.isOpen = !this.isOpen;
    },
    closeSideMenu() {
      this.isOpen = false;  // Fecha o menu ao clicar no item
    },
    checkMobileScreen() {
      const isMobile = window.innerWidth <= 991.98;
      if (!isMobile && this.isOpen) {
        this.isOpen = false; // Fecha automaticamente em telas grandes
      }
    },
  },
  mounted() {
    // Verifica inicialmente e adiciona o evento de resize
    this.checkMobileScreen();
    window.addEventListener("resize", this.checkMobileScreen);
  },
  beforeUnmount() {
    // Remove o evento de resize
    window.removeEventListener("resize", this.checkMobileScreen);
  },
};
</script>

<style scoped>
/* Botão para abrir o menu */
.side-menu-toggle {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
}

/* Estilo geral do menu lateral */
.side-sidemenu {
  position: fixed;
  top: 0;
  left: -100%; /* Esconde completamente fora da tela */
  width: 100%; /* Ocupa 100% da largura ao abrir */
  height: 100%;
  background-color: #f4f4f4;
  color: white;
  overflow-y: auto;
  transition: left 0.3s ease-in-out; /* Transição suave */
  z-index: 1000;
}
.side-sidemenu ul {
  padding: 10px;
  margin-top: 60px;
}
.side-sidemenu li a {
  text-align: center;
}
/* Menu aberto */
.side-sidemenu--open {
  left: 0; /* Move o menu completamente para dentro da tela */
}

/* Botão de fechar */
.side-close-button {
  font-size: 24px;
  background: none;
  border: none;
  color: white;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

/* Overlay escuro */
.side-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Escurece o fundo */
  z-index: 999;
}
</style>
