import { createStore } from 'vuex'
import { pt } from 'date-fns/locale'

export default createStore({
  state: {
    load: 0,
    block: 0,
    login: 0,

    user: '',
    filter: null,
    filterConfig: null,

    noResultsText: 'Nenhum resultado encontrado',
    loadingText: 'Carregando...',
    clearAllText: 'Limpar tudo',
    clearValueText: 'Limpar valor',
    noOptionsText: 'Sem opções disponíveis',

    erro: '',
    sucesso: '',

    lang: pt,

    isSideMenuOpen: false,
  },
  getters: {
    // Getter opcional
    isSideMenuOpen(state) {
      return state.isSideMenuOpen;
    }
  },
  mutations: {
    SETLOAD(state, a) {
      state.load = a;
    },
    SETBLOCK(state, a) {
      state.block = a;
    },
    SETUSER(state, a) {
      state.user = a;
    },
    SETFILTER(state, a) {
      state.filter = a;
    },
    SETFILTERCONFIG(state, a) {
      state.filterConfig = a;
    },
    SETLogin(state, a) {
      state.login = a;
    },
    SETErro(state, a) {
      state.erro = a;
    },
    SETSucesso(state, a) {
      state.sucesso = a;
    },
    // Nova mutation para alterar isSideMenuOpen
    TOGGLE_SIDEMENU(state) {
      state.isSideMenuOpen = !state.isSideMenuOpen;
    },
    SET_SIDEMENU_STATE(state, value) {
      state.isSideMenuOpen = value;
    }
  },
  actions: {
  },
  modules: {
  }
})
